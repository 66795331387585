import React, {useRef} from "react";
import styled from "styled-components";
import {Button, styled as msc} from '@mui/material';
import SplitRockLogo from "../assets/splitrock_white_jt.png";
import {Link} from "react-router-dom";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import AboutMe from "../components/Sections/AboutMe";
import Projects from "../components/Sections/Projects";
import Experience from "../components/Sections/Experience";
import Social from "../components/Sections/Social";
import {useMediaQuery} from "react-responsive";
import Header from "../components/Header";

export default function EasyModePage() {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

    const swiperRef = useRef(null);
    const handleAfterInit = (swiper: any) => {
        swiperRef.current = swiper;
    };

    const handleSlideChange = (index: any) => {
        setCurrentSlide(index);
        // @ts-ignore
        swiperRef.current.slideTo(index);
    };


    return (
        <>
            {isTabletOrMobile && (
                <Header animationsPaused={false}/>
            )}

            <Container $isTabletOrMobile={isTabletOrMobile}>
                <Swiper
                    grabCursor={true}
                    pagination={false}
                    style={{width: "100%", height: "calc(100% - 48px)"}}
                    onSlideChange={(swiper: any) => setCurrentSlide(swiper.activeIndex)}
                    onAfterInit={handleAfterInit}
                >
                    <SwiperSlide style={{overflow: "auto"}}>
                        <SectionContent>
                            <AboutMe/>
                        </SectionContent>
                    </SwiperSlide>
                    <SwiperSlide style={{overflow: "auto"}}>
                        <SectionContent>
                            <Projects/>
                        </SectionContent>
                    </SwiperSlide>
                    <SwiperSlide style={{overflow: "auto"}}>
                        <SectionContent>
                            <Experience/>
                        </SectionContent>
                    </SwiperSlide>
                    <SwiperSlide style={{overflow: "auto"}}>
                        <SectionContent>
                            <Social/>
                        </SectionContent>
                    </SwiperSlide>
                </Swiper>

                <NavigationBar $isTabletOrMobile={isTabletOrMobile}>
                    <NavigationButton
                        $isonselection={currentSlide === 0}
                        onClick={() => handleSlideChange(0)}
                    >
                        ABOUT
                    </NavigationButton>
                    <NavigationButton
                        $isonselection={currentSlide === 1}
                        onClick={() => handleSlideChange(1)}
                    >
                        PROJECTS
                    </NavigationButton>
                    <NavigationButton style={{flex: 0.2}}
                                      component={Link}
                                      to={isTabletOrMobile ? "/bio" : "/"}
                    >
                        <img src={SplitRockLogo} alt="Split Rock Logo" style={{height: "auto", width: "100%"}}/>
                    </NavigationButton>
                    <NavigationButton
                        $isonselection={currentSlide === 2}
                        onClick={() => handleSlideChange(2)}
                    >
                        WORK
                    </NavigationButton>
                    <NavigationButton style={{border: "none"}}
                                      $isonselection={currentSlide === 3}
                                      onClick={() => handleSlideChange(3)}
                    >
                        SOCIAL
                    </NavigationButton>
                </NavigationBar>
            </Container>
        </>
    )
}

interface INavigationBar {
    $isTabletOrMobile?: boolean;
}
const NavigationBar = styled.div<INavigationBar>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;

  display: flex;
  //align-items: center;
  //justify-content: space-between;

  border-top: 2px solid rgb(61, 61, 61);

  //padding: 0 24px;
`;

interface INavigationButton {
    $isonselection?: boolean;
    $isTabletOrMobile?: boolean;
    component?: any;
    to?: string;
}
const NavigationButton = msc(Button)<INavigationButton>`
    flex: 1;
    border-right: 2px solid rgb(61, 61, 61);
    border-right: 2px solid rgb(61, 61, 61);
    border-radius: 0;
    color: white;
    background: ${(props: any) => props.$isonselection ? "rgb(61, 61, 61)" : "inherit"};
`

interface IContainer {
    $isTabletOrMobile?: boolean;
}
const Container = styled.div<IContainer>`
  overflow: hidden;
  margin: ${(props) => (props.$isTabletOrMobile ? "0;" : "48px;")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${(props) => (props.$isTabletOrMobile ? "margin-top: 5em;" : "")};
  ${(props) => (props.$isTabletOrMobile ? "" : "max-width: 1000px;")};
  ${(props) => (props.$isTabletOrMobile ? "width: 100%;" : "")};
  justify-self: center;

  border: 2px solid rgb(61, 61, 61);
`;

const SectionContent = styled.div`
  padding: 2em;
`;
