import {createTheme, responsiveFontSizes} from "@mui/material";

declare module "@mui/material/styles" {
    interface Theme {
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
}

let theme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#1A202C",
            paper: "#1A202C",
        },
    },
    typography: {
        fontFamily: [
            "JetBrains Mono",
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
});
export default responsiveFontSizes(theme);
