import {Typography} from "@mui/material";
import React from "react";
import styled from "styled-components";
import DiffExDemoImg from "assets/diffex_dashboard_demo.png";
import {useMediaQuery} from "react-responsive";
import Robot from "assets/robot-big.png";
import BvcTechPreview from "assets/bvctechpreview.png";
import GbApp from "assets/gbapp.png";

export default function Projects() {
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

    return (
        <Cards isMobile={isTabletOrMobile}>
            <a href={"https:/diffex.splitrock.dev"} style={{textDecoration: "none", color: "white"}} target="_blank"
               rel="noopener noreferrer">
                <ProjectCard style={{cursor: "pointer"}}>
                    <img
                        alt={"DiffEx Dashboard Demo"}
                        style={{borderRadius: "15px"}}
                        src={DiffExDemoImg}
                        width={325}
                        height={165}
                    />

                    <Typography style={{fontSize: "20px"}}>DiffEx</Typography>
                    <Typography>
                        Easy to use web app for ETH blockchain options trading.
                    </Typography>
                </ProjectCard>
            </a>

            <a href={"https://github.com/first95"} style={{textDecoration: "none", color: "white"}} target="_blank"
               rel="noopener noreferrer">
                <ProjectCard style={{cursor: "pointer"}}>
                    <img
                        alt={"First Robotics Team"}
                        style={{borderRadius: "20px"}}
                        src={Robot}
                    />
                    <Typography style={{fontSize: "20px"}}>First Robotics</Typography>
                    <Typography>Teaching students how build robots</Typography>
                </ProjectCard>
            </a>

            <a href={"https://github.com/0NotApplicable0/-bvc/tree/develop"}
               style={{textDecoration: "none", color: "white"}} target="_blank" rel="noopener noreferrer">
                <ProjectCard style={{cursor: "pointer"}}>
                    <img
                        alt={"Bagels Versus Cats"}
                        style={{borderRadius: "20px", width: "325px", height: "165px"}}
                        src={BvcTechPreview}
                    />
                    <Typography style={{fontSize: "20px"}}>Bagels vs. Cats</Typography>
                    <Typography>Plants vs. Zombies like game using babylon.js</Typography>
                </ProjectCard>
            </a>

            <a href={"https://app.forge.splitrock.dev"}
               style={{textDecoration: "none", color: "white"}} target="_blank" rel="noopener noreferrer">
                <ProjectCard style={{cursor: "pointer"}}>
                    <img
                        alt={"gunbuilder.app"}
                        style={{borderRadius: "20px", width: "325px", height: "165px"}}
                        src={GbApp}
                    />
                    <Typography style={{fontSize: "20px"}}>The Forge</Typography>
                    <Typography>
                        Curated part builder made in three.js
                    </Typography>
                </ProjectCard>
            </a>
        </Cards>
    );
}

const ProjectCard = styled.div`
  width: 325px;
  margin: 1em;

`;

interface ICards {
    isMobile: boolean;
}

const Cards = styled.div<ICards>`
  display: flex;
  // flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;
