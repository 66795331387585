import {Typography} from "@mui/material";
import React from "react";
import styled from "styled-components";
import ProfileIcon from "assets/profile_icon.png";
import WavingHand from "assets/icons8-waving-hand-emoji-48.png";
import Grasshopper from "assets/icons8-grasshopper-48.png";
import {useMediaQuery} from "react-responsive";
import {HomeRepairService, House, QuestionMark, School} from "@mui/icons-material";

export default function AboutMe() {
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

    return (
        <div style={{display: "flex", flexDirection: "column", gap: '24px'}}>
            <Bio isMobile={isTabletOrMobile}>
                <div
                    key={1}
                    style={{textAlign: isTabletOrMobile ? "center" : "left"}}
                >
                    <Typography style={{fontSize: "32px"}}>Nick Arnold</Typography>
                    <Typography style={{fontSize: "18px"}}>
                        Digital Craftsman (Developer & Designer)
                    </Typography>
                </div>

                <div key={2}>
                    <img
                        style={{padding: "25px"}}
                        width={"250px"}
                        src={ProfileIcon}
                        alt="Profile Icon"
                    />
                </div>
            </Bio>

            <About>
                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "center" : "left",
                        fontSize: "18px",
                        marginBottom: "0.5em",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <u>
                        About Me
                    </u>

                    <QuestionMark />
                </Typography>

                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "justify" : "left",
                        fontSize: "16px",
                    }}
                >
                    Hello{" "}
                    <img
                        alt="waving hand"
                        style={{position: "relative", top: "5px", right: "3px"}}
                        src={WavingHand}
                        width="20"
                    />
                    , my name is Nick! I'm a software developer and a digital craftsman.
                    For the past eight years I have been working on robotics, full stack
                    web development, and embedded hardware projects along with a little
                    work in blockchain tech. I've got a passion for developing unique and
                    customizable solutions to many problems. Currently I work as a
                    full-time software engineer at a sass company and in my off time I
                    enjoy contributing to different projects and helping out my local
                    First Robotics team #95, The Grasshoppers{" "}
                    <img
                        alt="grasshopper"
                        style={{position: "relative", top: "2px", right: "0px"}}
                        src={Grasshopper}
                        width="20"
                    />
                    .
                </Typography>
            </About>

            <About>
                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "center" : "left",
                        fontSize: "18px",
                        marginBottom: "0.5em",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <u>
                        Education
                    </u>

                    <School />
                </Typography>

                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "justify" : "left",
                        fontSize: "16px",
                    }}
                >
                    <b>Bs. Computer Science</b>
                    <br />
                    Renesselaer Polytechnic Institute
                    <br />
                    2018 - 2021
                </Typography>
            </About>

            <About>
                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "center" : "left",
                        fontSize: "18px",
                        marginBottom: "0.5em",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <u>
                        Location
                    </u>

                    <House/>
                </Typography>

                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "justify" : "left",
                        fontSize: "16px",
                    }}
                >
                    <b>Greater Boston Area</b>
                </Typography>
            </About>

            <About>
                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "center" : "left",
                        fontSize: "18px",
                        marginBottom: "0.5em",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <u>
                        Known Technologies
                    </u>

                    <HomeRepairService />
                </Typography>

                <Typography
                    style={{
                        textAlign: isTabletOrMobile ? "justify" : "left",
                        fontSize: "16px",
                    }}
                >
                    <b>Frontend:</b> React, Redux, Material-UI, Bootstrap, HTML, CSS, JavaScript, TypeScript
                    <br />
                    <b>Backend:</b> Node.js, C#, ASP.NET, Entity Framework, SQL
                </Typography>
            </About>
        </div>
    );
}

const About = styled.div`
  //margin: 0px 25vw;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  line-height: 32;

  // DEBUG STYLES //
  // border: orange solid 2px;
`;

interface IBio {
    isMobile: boolean;
}

const Bio = styled.div<IBio>`
  //margin: 0px 25vw;
  
  display: flex;
  align-items: ${(props) => (props.isMobile ? "center" : "flex-start")};
  justify-content: ${(props) => (props.isMobile ? "center" : "space-between")};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  // DEBUG STYLES //
  // border: blue solid 2px;
`;
