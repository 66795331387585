import {Button, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

export default function NotFoundPage() {
    const navigate = useNavigate();

    return (
        <Container>
            <Typography>Sorry, nothing here :(</Typography>
            <Button onClick={() => navigate("/")}>Home</Button>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
