import styled from "styled-components";
import LinkedInLogo from "assets/linkedin.png";
import GitHubLogo from "assets/github.png";
import {Article} from "@mui/icons-material";
import {Typography} from "@mui/material";

export default function Social() {
    return (
        <Container>
            <Block href={"https://www.linkedin.com/in/programmernick/"}
               style={{textDecoration: "none", color: "white"}} target="_blank" rel="noopener noreferrer"
            >
                <Image>
                    <img
                        src={LinkedInLogo}
                        alt="LinkedIn"
                        width={300}
                    />
                </Image>
                <Typography style={{flex: 0.25}}>
                    LinkedIn
                </Typography>
            </Block>

            <Block href={"https://github.com/0NotApplicable0"}
               style={{textDecoration: "none", color: "white"}} target="_blank" rel="noopener noreferrer"
            >
                <Image>
                    <img
                        src={GitHubLogo}
                        alt="GitHub"
                        width={200}
                        style={{flex: 1}}
                    />
                </Image>
                <Typography style={{flex: 0.25}}>
                    GitHub
                </Typography>
            </Block>

            <Block href={"/Nicholas Arnold, Resume.pdf"}
               style={{textDecoration: "none", color: "white"}} target="_blank" rel="noopener noreferrer"
            >
                <Article
                    style={{fontSize: "200px", flex: 1}}
                />
                <Typography style={{flex: 0.25}}>
                    Resume
                </Typography>
            </Block>
        </Container>
    );
}

const Image = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`;

const Block = styled.a`
  height: 300px;
  flex: 1;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(61, 61, 61);
`;
