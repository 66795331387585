import styled from "styled-components";
import {animated, Spring} from "react-spring";
import {Typography} from "@mui/material";
import React from "react";

export default function Footer() {
    return (
        <Spring from={{opacity: 0}} to={{opacity: 1}}>
            {(style: any) => (
                <FooterContainer style={style}>
                    <Spring from={{y: 2000}} to={{y: 0}} delay={400}>
                        {(style: any) => (
                            <animated.div
                                style={{
                                    ...style,
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "2em",
                                }}
                            >
                                <div style={{marginBottom: "2em"}}>
                                    <Typography style={{padding: "0.35em"}}>
                                        {new Date().getFullYear()} Nick Arnold | splitrock.dev
                                    </Typography>
                                </div>
                            </animated.div>
                        )}
                    </Spring>
                </FooterContainer>
            )}
        </Spring>
    )
}

const FooterContainer = styled(animated.div)`
  flex: 0;
`;
