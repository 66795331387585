import {ContactPage, Fingerprint, NoteAlt, Terminal} from "@mui/icons-material";
import {IconButton, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {animated} from "react-spring";
import {styled as msc} from "@mui/system";
import {Rnd} from "react-rnd";
import AboutMe from "../Sections/AboutMe";
import Shell from "../FakeTerminal/Shell";

import RedRiverLogo from "../../assets/red-river-logo-140.png";
import DiffExLogo from "../../assets/diffex_logo_not.png";
import TheForgeLogo from "assets/TheForgeLogo.png";
import GithubLogo from "assets/github.png";
import LinkedinLogo from "assets/linkedin.png";
import Team95 from "assets/robot.png";

interface IApp {
    id: string;
    key?: string;
    x: number;
    y: number;
    width: number;
    height: number;
    url?: string;
}

export default function Desktop() {
    const [openApps, setOpenApps] = useState<Array<IApp>>([]);
    const [openAppsHidden, setOpenAppsHidden] = useState<Array<string>>([]);

    useEffect(() => {
        if (openAppsHidden.length === 0) return;

        // All of openApps are in openAppsHidden so we can reset openApps... //
        if (openAppsHidden.length === openApps.length) {
            setOpenApps([]);
            setOpenAppsHidden([]);
        }

        // eslint-disable-next-line
    }, [openAppsHidden]);

    return (
        <DesktopContainer>
            <DesktopSection>
                <DesktopSectionTitle>
                    About Me & Work Experience
                </DesktopSectionTitle>

                <DesktopSectionContent>
                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "bio",
                                    key: "AboutMe",
                                    x: 0,
                                    y: 0,
                                    width: 800,
                                    height: 600,
                                },
                            ]);

                        }}
                    >
                        <Fingerprint style={{fontSize: "75px"}}/>
                        <Typography>
                            Bio
                        </Typography>
                    </DesktopApp>

                    <DesktopAppLink href={"/Nicholas Arnold, Resume.pdf"}
                                    style={{textDecoration: "none", color: "white"}} target="_blank"
                                    rel="noopener noreferrer">
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <ContactPage style={{fontSize: "75px"}}/>
                        </div>
                        <Typography>Resume</Typography>
                    </DesktopAppLink>

                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "red-river",
                                    key: "RedRiver",
                                    x: 0,
                                    y: 0,
                                    width: 800,
                                    height: 600,
                                },
                            ]);

                        }}
                    >
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <img alt={"Red River Logo"} src={RedRiverLogo} width={"65px"}/></div>
                        <Typography>Red River</Typography>
                    </DesktopApp>

                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "logicbroker",
                                    key: "LogicBroker",
                                    x: 0,
                                    y: 0,
                                    width: 800,
                                    height: 600,
                                },
                            ]);

                        }}
                    >
                        <div style={{
                            width: "100%",
                            flex: 1,
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <svg
                                className="block w-full nav-logo is-page-scrolling:child:fill-white child:transition-all"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.088 48">
                                <path d="M57.366 35.72V14.327l3.265-1.711v23.1Z" fill="#e71618"></path>
                                <path
                                    d="M70.071 36.07c-4.183 0-7.321-3.455-7.321-8.654 0-5.451 3.233-8.682 7.416-8.682 4.247 0 7.417 3.389 7.417 8.589.003 5.512-3.231 8.747-7.512 8.747Zm-.032-14.231c-2.471 0-3.992 2.187-3.992 5.484 0 3.644 1.68 5.639 4.151 5.639s4.089-2.5 4.089-5.546c.003-3.395-1.616-5.577-4.245-5.577Z"
                                    fill="#e71618"></path>
                                <path
                                    d="m84.938 41.836-1.109-2.471c4.691-.221 5.7-1.49 5.7-4.943v-.285a6.274 6.274 0 0 1-4.309 1.933c-3.55 0-6.4-2.6-6.4-8.94 0-5.7 3.137-8.4 6.56-8.4a5.774 5.774 0 0 1 4.152 1.9v-1.551h3.264v15.056c.002 5.072-1.832 7.513-7.858 7.701Zm4.659-17.9a5.335 5.335 0 0 0-3.9-2.187c-2.344 0-3.614 1.773-3.614 5.293 0 4.183 1.333 6.021 3.741 6.021a5.113 5.113 0 0 0 3.772-2.125Z"
                                    fill="#e71618"></path>
                                <path
                                    d="M97.363 16.927a2.107 2.107 0 1 1 2.091-2.092 2.117 2.117 0 0 1-2.091 2.092Zm-1.65 18.794V19.082h3.265V35.72Z"
                                    fill="#e71618"></path>
                                <path
                                    d="M112.352 23.869a5.46 5.46 0 0 0-4.056-2.031c-2.377 0-3.9 2.124-3.9 5.516 0 3.261 1.393 5.608 3.992 5.608 1.554 0 2.474-.7 3.772-2.059l2.091 2.059a7.061 7.061 0 0 1-5.958 3.108c-4.058 0-7.194-3.425-7.194-8.62 0-5.515 3.234-8.716 7.289-8.716a7.357 7.357 0 0 1 5.9 2.82Z"
                                    fill="#e71618"></path>
                                <path
                                    d="M123.792 36.07a5.908 5.908 0 0 1-4.183-1.9v1.553h-3.232V14.296l3.232-1.68v8.051a6.337 6.337 0 0 1 4.342-1.933c3.581 0 6.4 2.6 6.4 8.936.002 5.704-3.103 8.4-6.559 8.4Zm-.443-14.326a5.111 5.111 0 0 0-3.771 2.125v7a5.332 5.332 0 0 0 3.9 2.188c2.345 0 3.612-1.776 3.612-5.293-.001-4.179-1.331-6.02-3.741-6.02Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M141.415 22.537a4.863 4.863 0 0 0-2.5-.6c-2.061 0-3.263 1.458-3.263 4.278v9.506h-3.266V19.082h3.266v1.586a4.6 4.6 0 0 1 3.8-1.933 4.8 4.8 0 0 1 2.821.7Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M149.31 36.07c-4.183 0-7.322-3.455-7.322-8.654 0-5.451 3.234-8.682 7.416-8.682 4.247 0 7.417 3.389 7.417 8.589-.003 5.512-3.232 8.747-7.511 8.747Zm-.033-14.231c-2.471 0-3.992 2.187-3.992 5.484 0 3.644 1.679 5.639 4.152 5.639s4.089-2.5 4.089-5.546c0-3.395-1.618-5.577-4.249-5.577Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="m168.262 35.72-3.676-8.27-2.535 3.359v4.911h-3.268V14.327l3.265-1.711v10.491c0 1.013-.032 2.726-.032 3.549.445-.667 1.3-1.933 1.839-2.726l3.453-4.85h3.71l-4.217 5.578 5.009 11.061Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M186.108 28.557h-11c.347 3.2 2.152 4.565 4.183 4.565a5.826 5.826 0 0 0 3.613-1.333l1.933 2.094a7.742 7.742 0 0 1-5.737 2.187c-3.9 0-7.193-3.138-7.193-8.654 0-5.641 2.979-8.682 7.288-8.682 4.722 0 7 3.835 7 8.335.008.601-.056 1.138-.087 1.488Zm-7.132-6.877c-2.186 0-3.519 1.522-3.8 4.184h7.734c-.161-2.279-1.239-4.184-3.934-4.184Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M197.232 22.537a4.866 4.866 0 0 0-2.5-.6c-2.062 0-3.265 1.458-3.265 4.278v9.506h-3.266V19.082h3.266v1.586a4.6 4.6 0 0 1 3.8-1.933 4.8 4.8 0 0 1 2.821.7Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M195.16 30.89a2.447 2.447 0 1 1-.01 0Zm-.01.384a2.089 2.089 0 0 0 .01 4.172 2.089 2.089 0 0 0 0-4.172Zm-.461 3.515h-.438V32.04a4.99 4.99 0 0 1 .777-.065 1.445 1.445 0 0 1 .855.208.659.659 0 0 1 .252.569.684.684 0 0 1-.537.668v.022c.242.043.406.262.46.667a2.274 2.274 0 0 0 .175.679h-.46a2.008 2.008 0 0 1-.186-.7.514.514 0 0 0-.592-.482h-.306Zm0-1.522h.318c.361 0 .667-.132.667-.471 0-.242-.175-.482-.667-.482a2.15 2.15 0 0 0-.318.022Z"
                                    fill="#6c6c70"></path>
                                <path
                                    d="M48.114 24.847c.009-.259.02-.518.02-.78a24.279 24.279 0 0 0-.507-4.933l-1.938.055a21.742 21.742 0 0 0-1.591-4.523l1.534-1.284a24.183 24.183 0 0 0-3.1-4.745l-1.79 1.005a22.164 22.164 0 0 0-3.647-3.29l.811-1.968a24 24 0 0 0-4.943-2.677l-1.191 1.836a21.953 21.953 0 0 0-4.788-1.136l-.209-2.25a23.318 23.318 0 0 0-5.613.025l-.212 2.311a21.868 21.868 0 0 0-4.647 1.255L15.02 1.766a24.033 24.033 0 0 0-4.942 2.723l.925 2.238a22.1 22.1 0 0 0-3.357 3.166L5.539 8.709a24.127 24.127 0 0 0-3.093 4.783l1.881 1.574a21.752 21.752 0 0 0-1.413 4.143l-2.406-.07A24.229 24.229 0 0 0 0 24.066c0 .277.011.548.02.821l2.347.507A22.024 22.024 0 0 0 2.98 29.5l-2.1 1.025a23.987 23.987 0 0 0 2.24 5.393l2.224-.616a21.7 21.7 0 0 0 2.32 3.243l-1.322 1.8a24.086 24.086 0 0 0 4.488 3.821l1.584-1.486a21.89 21.89 0 0 0 3.475 1.827l-.314 2.084a23.928 23.928 0 0 0 5.752 1.384l.666-1.916a22.458 22.458 0 0 0 2.311.12c.559 0 1.113-.027 1.664-.068l.655 1.89a23.957 23.957 0 0 0 5.752-1.34l-.284-1.907a21.878 21.878 0 0 0 3.706-1.82l1.391 1.306a24.293 24.293 0 0 0 4.495-3.774l-1.1-1.5a21.894 21.894 0 0 0 2.588-3.5l1.816.5a23.981 23.981 0 0 0 2.24-5.358l-1.671-.818a21.865 21.865 0 0 0 .691-4.54ZM24.187 39.354a15.11 15.11 0 1 1 15.109-15.108 15.109 15.109 0 0 1-15.109 15.108Z"
                                    fill="#e71618"></path>
                            </svg>
                        </div>
                        <Typography>Logicbroker</Typography>
                    </DesktopApp>

                </DesktopSectionContent>
            </DesktopSection>

            <DesktopSection>
                <DesktopSectionTitle>
                    Projects & Socials
                </DesktopSectionTitle>

                <DesktopSectionContent>
                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "diffex",
                                    url: "https://diffex.splitrock.dev",
                                    x: 0,
                                    y: 0,
                                    width: 1200,
                                    height: 1000,
                                },
                            ]);

                        }}
                    >
                        <img alt={"DiffEx Logo"} src={DiffExLogo} width={"75px"}/>
                        <Typography>DiffEx</Typography>
                    </DesktopApp>

                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "theforge",
                                    url: "https://app.forge.splitrock.dev",
                                    x: 0,
                                    y: 0,
                                    width: 1200,
                                    height: 1000,
                                },
                            ]);

                        }}
                    >
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <img alt={"The Forge Logo"} src={TheForgeLogo} width={"65px"}/>
                        </div>
                        <Typography>The Forge</Typography>
                    </DesktopApp>

                    <DesktopAppLink href={"https://github.com/0NotApplicable0"}
                                    style={{textDecoration: "none", color: "white"}} target="_blank"
                                    rel="noopener noreferrer">
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <img alt={"Github Logo"} src={GithubLogo} width={"65px"}/>
                        </div>
                        <Typography>Github</Typography>
                    </DesktopAppLink>

                    <DesktopAppLink href={"https://linkedin.com/programmernick"}
                                    style={{textDecoration: "none", color: "white"}} target="_blank"
                                    rel="noopener noreferrer">
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <img alt={"LinkedIn Logo"} src={LinkedinLogo} width={"65px"}/>
                        </div>
                        <Typography>LinkedIn</Typography>
                    </DesktopAppLink>

                    <DesktopAppLink href={"https://github.com/first95"}
                                    style={{textDecoration: "none", color: "white"}} target="_blank"
                                    rel="noopener noreferrer">
                        <div style={{flex: 1, display: 'flex', justifyContent: "center", alignItems: "center"}}>
                            <img alt={"Team95 Logo"} src={Team95} width={"65px"}/>
                        </div>
                        <Typography>Team 95</Typography>
                    </DesktopAppLink>
                </DesktopSectionContent>
            </DesktopSection>

            <DesktopSection>
                <DesktopSectionTitle>
                    Apps
                </DesktopSectionTitle>

                <DesktopSectionContent>
                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "notepad",
                                    url: "https://onlinenotepad.org/notepad",
                                    x: 0,
                                    y: 0,
                                    width: 800,
                                    height: 600,
                                },
                            ]);
                        }}
                    >
                        <NoteAlt style={{fontSize: "75px"}}/>
                        <Typography>Notepad</Typography>
                    </DesktopApp>

                    <DesktopApp
                        onClick={() => {
                            setOpenApps([
                                ...openApps,
                                {
                                    id: "shell",
                                    key: "shell",
                                    x: 0,
                                    y: 0,
                                    width: 800,
                                    height: 600,
                                },
                            ]);
                        }}
                    >
                        <Terminal style={{fontSize: "75px"}}/>
                        <Typography>Terminal</Typography>
                    </DesktopApp>
                </DesktopSectionContent>
            </DesktopSection>

            {openApps.length > 0 && (
                <>
                    {openApps.map((app, index) => (
                        <Rnd
                            onResizeStop={(e, direction, ref, delta, position) => {
                                setOpenApps(
                                    openApps.map((app, i) =>
                                        i === index
                                            ? {
                                                ...app,
                                                width: ref.offsetWidth,
                                                height: ref.offsetHeight,
                                            }
                                            : app
                                    )
                                );
                            }}
                            onDragStop={(e, d) => {
                                setOpenApps(
                                    openApps.map((app, i) =>
                                        i === index
                                            ? {...app, x: d.x, y: d.y}
                                            : app
                                    )
                                );
                            }}
                            position={{x: app.x, y: app.y}}
                            enableResizing={app.key !== "shell"}
                            size={{width: app.width, height: app.height}}
                            style={{
                                zIndex: 100,
                                display: openAppsHidden.includes(app.id) ? "none" : ""
                            }}
                        >
                            <div style={{
                                padding: "3px",
                                background: "lightgrey",
                                display: "flex",
                                justifyContent: "flex-start",
                                borderRadius: "6px 6px 0 0"
                            }}>
                                <CloseWindow onClick={() => {
                                    setOpenAppsHidden([...openAppsHidden, app.id]);
                                }}>
                                </CloseWindow>
                            </div>

                            {app.url && (
                                <iframe
                                    style={{height: "100%", width: "100%", border: "none"}}
                                    src={app.url}
                                    title={"App Window"}
                                />
                            )}

                            {app.key === "AboutMe" && (
                                <div style={{
                                    background: "#2a2a2a",
                                    padding: "25px",
                                }}>
                                    <AboutMe/>
                                </div>
                            )}

                            {app.key === "RedRiver" && (
                                <div style={{
                                    background: "#2a2a2a",
                                    padding: "25px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px"
                                }}>
                                    <Typography>
                                        <a href={"https://redriver.com"}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <b>Red River</b>
                                        </a>
                                    </Typography>
                                    <Typography>
                                        Duration: 2019 - 2021
                                    </Typography>
                                    <Typography>
                                        Position: Software Engineer I
                                    </Typography>
                                    <Typography>
                                        Description:
                                        Internship focused on mobile app development, automated console apps,
                                        database design, and SQL Stored Procedures.
                                    </Typography>
                                    <div>
                                        <Typography>
                                            Tech Used:
                                        </Typography>
                                        <ul>
                                            <li>Typescript & Javascript</li>
                                            <li>React & React Native</li>
                                            <li>C# & .NET</li>
                                            <li>MSQL & SSMS</li>
                                            <li>Git & Azure DevOps</li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {app.key === "LogicBroker" && (
                                <div style={{
                                    background: "#2a2a2a",
                                    padding: "25px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px",
                                }}>
                                    <Typography>
                                        <a href={"https://logicbroker.com"}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            <b>Logicbroker</b>
                                        </a>
                                    </Typography>
                                    <Typography>
                                        Duration: 2021 - present
                                    </Typography>
                                    <Typography>
                                        Description:
                                        Full time position developing a learning management system and scalable
                                        eCommerce platform.
                                        Weekly scrums and AGILE development to maintain and advance an infinitely
                                        scalable drop ship and marketplace platform.
                                    </Typography>
                                    <div>
                                        <Typography>
                                            Tech Used:
                                        </Typography>
                                        <ul>
                                            <li>React</li>
                                            <li>Typescript & Javascript</li>
                                            <li>C# & .NET</li>
                                            <li>Material UI & styled-components</li>
                                            <li>Azure Cloud Systems</li>
                                            <li>Git, Asana, Jenkins</li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {app.key === "shell" && (
                                <Shell
                                    openApps={openApps}
                                    setOpenApps={setOpenApps}
                                />
                            )}
                        </Rnd>
                    ))}
                </>
            )}
        </DesktopContainer>
    )
}

const CloseWindow = styled(IconButton)`
  background: #fc5b57 !important;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: 0 3px;
  border-radius: 50%;
`;


const DesktopContainer = styled(animated.div)`
  padding: 125px 25px 125px 25px;
  height: calc(100vh - 88px);
  //width: 100vw;

  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

const DesktopSection = styled.div`
  overflow: auto;
  
  flex: 1;
  min-width: 40vw;
  height: 50%;

  display: flex;
  flex-direction: column;

  padding: 25px 25px 25px 25px;

  border: 1px dashed rgb(61, 61, 61);
`;

const DesktopSectionContent = styled.div`
  flex: 1;

  padding: 15px 0;

  display: flex;
  flex-wrap: wrap;
  gap: 25px;
`;

const DesktopSectionTitle = msc(Typography)`
    font-size: 20px;
    flex: 0;
    margin: 10px;
`;

const DesktopApp = styled.div`
  cursor: pointer;
  min-width: 75px;
  max-width: 90px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DesktopAppLink = styled.a`
  cursor: pointer;
  min-width: 75px;
  max-width: 90px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
