import React from "react";
import styled from "styled-components";
import {ReactTerminal} from "react-terminal";

interface IProps {
    openApps: any;
    setOpenApps: any;
}

export default function Shell(props: IProps) {
    const {openApps, setOpenApps} = props;

    const help = (
        <span>
          <strong>Commands:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
            <strong>help(module)</strong> - Get more information on a selected
            module.
            <br/>
            <strong>clear</strong> - Clears the console. <br/>
            <strong>easymode</strong> - Switches to easy mode. <br/>
            <strong>ls</strong> - lists files in current directory. <br/>
            <strong>./[[file]]</strong> - Runs a specific file. <br/>
            <strong>echo</strong> - Repeats what you type. <br/>
            <strong>domath</strong> - Does math for you. <br/>
          </div>

          <br/>
          <strong>Modules:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
              <strong>nick</strong>
              <br/>
              <strong>diffex</strong>
              <br/>
              <strong>theforge</strong>
              <br/>
              <strong>firstrobotics</strong>
          </div>
          <br/>
          <strong>For my bio please type `nick.bio`</strong> <br/>
          <strong>For my info on other modules type '[[module]].info'</strong>
        </span>
    );

    const moduleNickHelp = (
        <span>
          <strong>Variables:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
            <strong>bio</strong> - Get to know more about me.
            <br/>
          </div>
        </span>
    );

    const moduleDiffexHelp = (
        <span>
          <strong>Variables:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
            <strong>info</strong> - Get to know more about diffex.
            <br/>
          </div>
        </span>
    );

    const moduleTheForgeHelp = (
        <span>
          <strong>Variables:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
            <strong>info</strong> - Get to know more about the forge.
            <br/>
          </div>
        </span>
    );

    const moduleFirstRoboticsHelp = (
        <span>
          <strong>Variables:</strong>
          <br/>
          <div style={{marginLeft: "2em"}}>
            <strong>info</strong> - Get to know more about my participation in First.
            <br/>
          </div>
        </span>
    );

    const diffexInfo = (
        <span>
          DiffEx is a web app that allows users to trade options on the Ethereum
          blockchain. The app is built using React, Redux, and Solidity.
        </span>
    );

    const theForgeInfo = (
        <span>
          The Forge is a part builder for 3D building. The app is built using
          Three.js and React.
        </span>
    );

    const firstRoboticsInfo = (
        <span>
          I mentor a local First Robotics team, The Grasshoppers, #95. I help teach
          students how to build robots and compete in competitions.
        </span>
    );

    const bio = (
        <span>
          Hello, my name is Nick.<br/>
          <ul>
            <li>
              <strong>Where do I live?</strong>
              <br/>
              Greater Boston Area
            </li>
            <li>
              <strong>Where did I go to school?</strong>
              <br/>
              Rensselaer Polytechnic Institute
            </li>
            <li>
              <strong>What did I major in?</strong>
              <br/>
              Computer science
            </li>
            <li>
              <strong>Where do I currently work?</strong>
              <br/>
              Logicbroker
            </li>
            <li>
              <strong>How can you contact me?</strong>
              <br/>
              nick@splitrock.dev
            </li>
          </ul>
        </span>
    );

    const commands = {
        "nick.bio": bio,
        "nick": bio,
        "diffex.info": diffexInfo,
        "diffex": diffexInfo,
        "theforge.info": theForgeInfo,
        "theforge": theForgeInfo,
        "firstrobotics.info": firstRoboticsInfo,
        "firstrobotics": firstRoboticsInfo,

        "help(nick)": moduleNickHelp,
        "help(diffex)": moduleDiffexHelp,
        "help(theforge)": moduleTheForgeHelp,
        "help(firstrobotics)": moduleFirstRoboticsHelp,

        ls: () => {
            return (
                <span>
                    diffex
                    <br/>
                    theforge
                </span>
            );
        },

        "easymode": () => {
            window.location.href = "/bio";
        },

        "./diffex": () => {
            setOpenApps([...openApps, {
                id: "diffex",
                title: "DiffEx",
                x: 0,
                y: 0,
                width: 600,
                height: 600,
                url: "https://diffex.splitrock.dev"
            }]);
        },
        "./theforge": () => {
            setOpenApps([...openApps, {
                id: "theforge",
                title: "The Forge",
                x: 0,
                y: 0,
                width: 600,
                height: 600,
                url: "https://theforge.splitrock.dev"
            }]);
        },

        notepad: () => {
            setOpenApps([...openApps, {
                id: "notepad",
                title: "Notepad",
                x: 0,
                y: 0,
                width: 600,
                height: 600,
                url: "https://onlinenotepad.org/notepad"
            }]);
        },

        calculator: () => {
            setOpenApps([...openApps, {
                id: "calculator",
                title: "Calculator",
                x: 0,
                y: 0,
                width: 600,
                height: 600,
                url: "https://www.online-calculator.com/full-screen-calculator/"
            }]);
        },

        whoami: "Im not sure, who are you? To figure out more about me try: whoareyou.",
        echo: (text: string) => {
            return <span>{text}</span>;
        },
        help: help,
        "?": help,
        domath: async (expr: string) => {
            const response = await fetch(
                `https://api.mathjs.org/v4/?expr=${encodeURIComponent(expr)}`
            );
            return await response.text();
        },
    };

    return (
        <Container>
            <ReactTerminal
                showControlBar={false}
                commands={commands}
                themes={{
                    splitrock: {
                        themeBGColor: "#272B36",
                        themeToolbarColor: "#DBDBDB",
                        themeColor: "#FFFEFC",
                        themePromptColor: "red",
                    },
                }}
                theme={"material-dark"}
                welcomeMessage={
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Welcome to splitrock.dev, get started with 'help' or
                        '?'. <br/>
                    </span>
                }
                errorMessage={`I don't know that one, try using 'help' or '?'`}
                editorStyle={{fontSize: "clamp(10px, 2vw, 16px)"}}
            />
        </Container>
    );
}

const Container = styled.div`
  height: 500px;
  
  max-width: 1000px;
  max-height: 600px;
  background: #3c3c3c;

  border-radius: 10px;
`;
