import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import "react-image-lightbox/style.css";

import React from "react";
import {Route, Routes} from "react-router-dom";
import HomePage from "containers/HomePage";
import NotFoundPage from "containers/NotFoundPage";
import styled from "styled-components";
import EasyModePage from "./containers/EasyModePage";

function App() {
    return (
        <Container>
            <Routes>
                <Route path="*" element={<NotFoundPage/>}/>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/bio" element={<EasyModePage/>}/>
            </Routes>
        </Container>
    );
}

const Container = styled.div`
  overflow: hidden;
`;

export default App;
