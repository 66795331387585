import React from "react";
import {TailSpin} from "react-loader-spinner";
import {animated, Spring} from "react-spring";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Desktop from "../components/Desktop/Desktop";

export default function HomePage() {
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1000px)"});
    if(isTabletOrMobile) {
        window.location.href = "/bio";
        return <></>;
    }

    return (
        <>
            <Header animationsPaused={false}/>

            <Container isMobile={isTabletOrMobile} $doneLoading={true}>
                <Desktop />
            </Container>

            <Spring from={{opacity: 1}} to={{opacity: 0}}>
                {(styles: any) => (
                    <animated.div style={styles}>
                        <Spring from={{opacity: 0}} to={{opacity: 1}}>
                            {(style: any) => (
                                <LoaderContainer style={style}>
                                    <TailSpin
                                        height="80"
                                        width="80"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        visible={true}
                                    />
                                </LoaderContainer>
                            )}
                        </Spring>
                    </animated.div>
                )}
            </Spring>

            <Footer/>
        </>
    );
}

interface IContainerProps {
    isMobile: boolean;
    $doneLoading: boolean;
}

const Container = styled.div<IContainerProps>`
  overflow: hidden;

  display: ${(props) => (props.$doneLoading ? "flex" : "none")};
  flex-direction: column;
`;

const LoaderContainer = styled(animated.div)`
  position: absolute;
  top: calc(50% - 40px);
  right: calc(50% - 40px);

  display: flex;
  justify-content: center;
  align-items: center; 
`;
