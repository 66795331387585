import React, {useEffect} from "react";
import styled from "styled-components";
import {Chrono} from "react-chrono";
import {useMediaQuery} from "react-responsive";

export default function Experience() {
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

    const items = [
        {
            title: '2019',
            cardTitle: 'Red River',
            cardSubtitle: 'Software Engineer ',
            cardDetailedText: [
                "Internship focused on mobile app development, automated console apps, database design, and SQL Stored Procedures.",
                "Tools Used: [SSMS, Visual Studio, VSCode]",
                "Learned: [DevOps, .NET, Azure CI/CD]"
            ],
        },
        {
            title: '2020',
            cardTitle: 'DiffEx',
            cardSubtitle: 'Co-Founder',
            cardDetailedText: [
                "Decentralized options exchanged on the Ethereum blockchain.",
                "Tools Used: [Solidity, React, Node.js, Web3.js]",
                "Learned: [Smart Contracts, Blockchain, Cryptography]",
            ],
        },
        {
            title: '2021',
            cardTitle: 'Logicbroker',
            cardSubtitle: 'Software Engineer III',
            cardDetailedText: [
                "Full time position developing a learning management system and scalable eCommerce portal.",
                "Tools Used: [React, C#, .NET, SQL Server]",
                "Learned: [Azure, Scrum, Asana]",
            ],
        },
    ];

    useEffect(() => {
        const popover = document.querySelector('[aria-label="timeline_popover"]');
        if (popover) popover.remove();
    }, [isTabletOrMobile]);

    return (
        <Work>
            {isTabletOrMobile ? (
                <Chrono
                    key={"mobile"}
                    items={items}
                    borderLessCards={true}
                    mode="HORIZONTAL"
                    allowDynamicUpdate
                    useReadMore={false}
                    theme={{
                        primary: 'rgba(144, 202, 249, 0.08)',
                        secondary: '#fff',
                        titleColor: '#fff',
                        cardTitleColor: '#000',
                        toolbarTextColor: '#000',
                        toolbarBgColor: '#121212',
                        toolbarBtnColor: "#000"
                    }}
                />
            ) : (
                <Chrono
                    key={"desktop"}
                    style={{flex: 1}}
                    items={items}
                    disableToolbar
                    borderLessCards={true}
                    mode="VERTICAL_ALTERNATING"
                    allowDynamicUpdate
                    useReadMore={false}
                    theme={{
                        primary: 'rgba(144, 202, 249, 0.08)',
                        secondary: '#fff',
                        titleColor: '#fff',
                        cardTitleColor: '#000',
                    }}
                />
            )}
        </Work>
    );
}

const Work = styled.div`
  display: flex;
  flex-direction: column;
`;
