import SplitRockLogo from "../assets/splitrock_white.png";
import {IconButton} from "@mui/material";
import {default as ssc} from "styled-components";
import React from "react";
import {Rocket} from "@mui/icons-material";
import {animated, Spring} from "react-spring";
import {Link, useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

interface IProps {
    animationsPaused: boolean;
}

export default function Header(props: IProps) {
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

    return (
        <Container $istabletormobile={isTabletOrMobile}>
            <Spring from={{x: -2000}} to={{x: 0}} pause={props.animationsPaused}>
                {(style: any) => (
                    <animated.div style={{...style, flex: 1}}>
                        <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                            <img
                                style={{cursor: "pointer", marginTop: "1em", marginLeft: "1em", padding: "0.25em"}}
                                onClick={() => navigate("/")}
                                src={SplitRockLogo}
                                alt={"Logo"}
                                width={"75px"}
                            />
                        </div>
                    </animated.div>
                )}
            </Spring>

            <Spring
                from={{x: 2000}}
                to={{x: 0}}
                delay={300}
                pause={props.animationsPaused}
            >
                {(style: any) => (
                    <animated.div style={{...style, flex: 0, alignItems: "center", marginTop: "1em", marginRight: "1em"}}>
                        <IconButton component={Link} to={"/bio"}>
                            <Rocket fontSize={"large"}/>
                        </IconButton>
                    </animated.div>
                )}
            </Spring>
        </Container>
    );
}

interface IContainer {
    $istabletormobile: boolean;
}

const Container = ssc.div<IContainer>`
  display: flex;
  padding: 0.25em;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  ${(props) => (props.$istabletormobile ? "backdrop-filter: blur(10px);" : "")};
  ${(props) => (props.$istabletormobile ? "z-index: 100;" : "")};
`;

